import React from "react";
import { AiOutlineCaretDown, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import BannerImage from "../../assets/images/bannerImage.png";
import "./Banner.css";
import { Link } from "react-router-dom";

const Banner = () => {
  const [dropdown, setdropdown] = React.useState(false);
  const [DownloadData, setDownloadData] = React.useState({});

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [termsAccepted, setTermAccepted] = React.useState(false);
  const getreviewssData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch("/Download-data.json", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setDownloadData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      //   console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        alert("Hash copied success");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  React.useEffect(() => {
    getreviewssData();
  }, []);

  return (
    <div>
      <div className="w-full container px-6 mx-auto flex flex-col lg:flex-row justify-center">
        <div className="lg:w-1/2">
          <div className="container border border-[#00ffc3] p-1 w-60 rounded-full flex items-center justify-between mb-4">
            <span className="font-inter text-xs font-medium text-white ml-3">
              Continue with Russian
            </span>
            <a
              href="https://hydraflasher.ru/"
              className="w-8 h-8 rounded-full flex justify-center items-center bg-[#00ffc3]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-arrow-right text-black font-semibold"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                />
              </svg>{" "}
            </a>
          </div>
          <h1 className="hydrat lg:text-7xl text-white mt-3 mb-4 text-4xl">
            Hydra Flasher{" "}
          </h1>
          <h1 className="hydrat lg:text-7xl text-white mt-3 mb-4 text-4xl">
            The Best Fake Bitcoin Sender{" "}
          </h1>
          <p className="">
            {" "}
            Hydra Bitcoin flasher software and mobile app is the latest version
            of the Bitcoin Flash software and mobile app since 2021. This tool
            is more reliable, faster and easy to use. It also cover you from any
            third party tracker. Hydra Flasher has a flashing limit of 100 BTC
            in a day. It is transferable to 9 different wallets and can last for
            191 days.{" "}
          </p>

          <div className="mt-8 flex items-center ">
            <div className="mr-5 relative">
              <Link to="/download">
                <button className="download-button flex items-center rounded-full bg-primary p-3 text-black font-mono  ">
                  <AiOutlineCaretDown className="mr-2"></AiOutlineCaretDown>{" "}
                  Download
                </button>
              </Link>

              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="download-button flex items-center rounded-full bg-primary p-3 text-black font-mono   mt-3"
              >
                Request a demo
              </button>

              <div
                class={`${
                  showModal ? "flex" : "hidden"
                } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
              >
                <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
                  <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Terms and conditions
                      </h3>
                      <button
                        onClick={() => {
                          setShowModal(false);
                        }}
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-toggle="defaultModal"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>

                    <div class="p-6 space-y-6">
                      <div className="max-h-[200px] overflow-auto border-2 p-3">
                        <p className="terms-text">
                          As a condition of use, you promise not to use the
                          HydraFlasher Demo Services for any purpose that is
                          unlawful or prohibited by these Terms. <br />
                          By way of example. and not as a limitatian. you agree
                          not to use the Services: <br />
                          For Demo flash, you have to submit a request to server
                          admistrator for a Demo flash.
                          <br /> We will provide a free license to every new
                          user. By this free license, user can flash 0.0001 BTC
                          in there own wallet for free of cost (user can Flash
                          There own). <br />
                          Hydra Flasher Representative will never ask Anydesk or
                          Team Viewer for demo flash.
                          <br />
                          User can Flash One Time with that Demo License. After
                          Flashing the Demo, License will Automatically
                          Deactive. <br />
                          For Continue or Active a Package License, user must go
                          through for a paid License From Shop.
                          <br />
                          Paid License will available in our Shop section. Our
                          old Sellix Shop is closed.
                        </p>
                      </div>

                      <div class="flex items-center mb-4">
                        <input
                          onChange={(e) => {
                            setTermAccepted(e.target.checked);
                          }}
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          I Agree to terms and conditions.
                        </label>
                        <br />
                      </div>

                      <button
                        onClick={() => {
                          setShowModal(false);
                          setShowModal2(true);
                        }}
                        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                        disabled={termsAccepted ? false : true}
                      >
                        Request a demo
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class={`${
                  showModal2 ? "flex" : "hidden"
                } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
              >
                <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
                  <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Submit Request
                      </h3>
                      <button
                        onClick={() => {
                          setShowModal2(false);
                        }}
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-toggle="defaultModal"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>

                    <div class="p-6 space-y-6">
                      <div>
                        <label
                          for="email"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          id="email"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          placeholder="name@flowbite.com"
                          required
                        />
                      </div>

                      <p>Request a demo by</p>
                      <p>
                        <a
                          href="mailto:hydrabtcflasher@gmail.com"
                          className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                        >
                          Email
                        </a>{" "}
                        or{" "}
                        <a
                          target={"_blank"}
                          href="https://t.me/hydraflasher

"
                          className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                        >
                          Telegram
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="dropdown"
                className={`${
                  dropdown ? "" : "hidden"
                } z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute`}
                data-popper-reference-hidden=""
                data-popper-escaped=""
                data-popper-placement="bottom"
              >
                <ul
                  className="py-1 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefault"
                >
                  <li>
                    <a
                      href={DownloadData.hydraflasher}
                      className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      HydraFlasher
                    </a>
                  </li>
                  <li>
                    <a
                      href={DownloadData.bitcoin_core}
                      className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Bitcoin Core 5.0.1
                    </a>
                  </li>
                  <hr></hr>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        copyTextToClipboard(DownloadData.copy_hash);
                      }}
                      className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Copy Hash
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* video button here */}
            <div>
              <NavLink to="/videos">
                <button className="youtube-button"></button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 mt-[70px] md:mt-0">
          <img
            className=" "
            // src="https://templates.hibotheme.com/cybex/default/assets/images/banner/banner-img-1.png"
            src={BannerImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
